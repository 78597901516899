export default {
      "engine_output": "Effekt",
      "cylinder_capacity": "Cylindervolym",
      "overview": "Översikt",
      "gallery": "Bildgalleri",
      "video": "Video",
      "equipment": "Utrustning",
      "accessories": "Tillbehör",
      "total_price": "Totalpris",
      "financing_from": "Finansiering från",
      "financial_services_calculator": "Lånekalkyl",
      "request_offer": "Kontakta mig",
      "close": "Stäng",
      "back": "Tillbaka",
      "models": "Modeller",
      "price": "Pris",
      "location": "Återförsäljare",
      "locations": "Platser",
      "thanks": "Tack för din förfrågan",
      "contacted": "Du kommer inkort bli kontaktad av återförsäljare.",
      "dealers_locator": "Hitta återförsäljare",
      "currency": "SEK",
      "monthly": "Månad",
      "reset_filters": "Återställ filter",
      "sort_by": "Sortera efter",
      "monthly_payment": "Uppskattad månadsavgift",
      "distance": "Avstånd",
      "fields": "Alla fält är obligatoriska",
      "downpayment": "Kontantinsats",
      "first_name": "Namn",
      "last_name": "Efternamn",
      "phone": "Telefon",
      "email": "E-post",
      "marketing": "Samtycke till mottagande av marknadsföring",
      "policy": "Ändra eller återkalla tidigare lämnat samtycke.",
      "search": " Sök inom ett specifikt område eller välj en återförsäljare nedan",
      "period": "Avtalstid",
      "remaining": "Restvärde",
      "financial_calc_text": "Friskrivning: Detta är det aktuella priset för just denna motorcykel. Priset bestäms av återförsäljaren med motorcykel i lager och kan komma att ändras. ",
      "financial_calc_text1": "Ränta :interest-rate% (effektiv ränta :effective-interest-rate%) enligt ränteläge mars 2019. För privatperson är minsta kontantinsats :min-downpayment%. Ovanstående beräkning är endast ett exempel som är baserat på en rörlig indikativ ränta. Lånet är ett annuitetslån, vilket ger en jämn kostnad under hela kontraktstiden. Uppläggnings- och administrationsavgift tillkommer med f.n. 595 kr resp. 55 kr. Vi gör ingen kreditförfrågan baserat på denna kalkyl. Kreditgivare BMW Financial Services Scandinavia AB.",
      "360_View": "360 Översikt",
      "vehicle_details_vehicle_identifier":"ID"
}