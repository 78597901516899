<template>
  <div id="app" class="embeded">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "./scss/components/checkbox.scss";
@import "./scss/components/close.scss";
@import "./scss/app.scss";
@font-face {
  font-family: "BMW Motorrad";
  font-weight: 300;
  src: url("../fonts/BMWMotorrad-Light.ttf");
}
@font-face {
  font-family: "BMW Motorrad";
  src: url("../fonts/BMWMotorrad-Regular.ttf");
}
@font-face {
  font-family: "BMW Motorrad";
  font-weight: 700;
  src: url("../fonts/BMWMotorrad-Bold.ttf");
}
@font-face {
  font-family: "BMW Motorrad";
  font-weight: 900;
  src: url("../fonts/BMWMotorrad-Heavy.ttf");
}

* {
  box-sizing: border-box;
  font-family: "BMW Motorrad";
}
html,
body {
  max-width: 100%;
  font-family: "BMW Motorrad";
  margin: 0;
}
#app{
  min-height:100vh
}
</style>
