import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import("../views/Home.vue")
  },
  {
    path: '/bike/:id',
    name: 'Details',
    component: () =>
      import("../views/BikeDetails")
  },
    {
    path: '/confirm/:id',
    name: 'Confirm',
    component: () =>
      import("../views/Confirm")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
