export default {
    "engine_output" : "Engine output",
    "cylinder_capacity": "Cylinder capacity",
    "overview": "Overview",
    "gallery": "Gallery",
    "video": "Video",
    "equipment": "Equipment",
    "accessories": "Accessories",
    "total_price": "Total Price",
    "financing_from": "Financing from ",
    "financial_services_calculator": "Finacial Services Calculator",
    "request_offer": "Request An offer",
    "close": "Close",
    "back": "Back",
    "models": "Models",
    "price": "Price",
    "location": "Location",
    "locations": "Locations",
    "thanks": "Thanks For Reaching Out",
    "contacted": "You’ll be contacted by our sales person soon. ",
    "dealers_locator": "Dealers Locator",
    "currency": "currency",
    "monthly": "Monthly",
    "reset_filters": "Reset Filters",
    "sort_by": "Sort By",
    "monthly_payment": "Estimated Monthly Payment",
    "distance": "Distance",
    "fields": "All Fields Are Required",
    "downpayment": "Downpayment",
    "first_name": "First Name",
    "last_name": "Last Name",
    "phone": "Phone",
    "email": "E-mail",
    "marketing": "My consent to marketing communications",
    "policy": "Amend or withdraw your consent.",
    "search": "Search within a specific area or select a retailer below",
    "period": "Period",
    "remaining": "Remaining",
    "financial_calc_text": "Disclaimer: This is the current price for this particular bike. The price is determined by the dealer for this stock bike and may change.",
    "financial_calc_text1": "Interest 4.25% according to interest rate situation March 2019. For private individuals, the minimum cash contribution is 20%. The above calculation is only an example based on a variable indicative interest rate. The loan is an annuity loan, which provides an even cost throughout the contract period. Set-up and administration fee will be added, from SEK 595 resp. 55 kr. We do not a credit check based on this calculation. BMW Financial Services Scandinavia AB.",
    "360_View": "360 View"





};