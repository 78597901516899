import Swedish from './se';
import English from './en';

class Locale {
    constructor() {
        this.translations = {};
        this.untranslated = {};
    }

    setLanguage(locale) {
        switch (locale) {
            case 'se':
                this.translations = Swedish;
                break;
            default:
                this.translations = English;
        }
        console.log("Language set", locale);
    }

    get(word) {
        if (this.translations[word]) {
            return this.translations[word];
        }
        else {
            this.untranslated[word] = word;
        }
        return word;
    }
}

export default Locale;